import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';
import Robotyze from './../../assets/images/Robotyze Logo.svg';
import Chisp from './../../assets/images/L Chisp logo.svg';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Companies',
    paragraph: 'I\'m presently a partial owner of two different LLCs. While they\'re both in their infant stages, each provides a way for me to apply my knowledge toward different technical challenges that could greatly benefit society.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Button image={Robotyze} tag="a" color="light" wideMobile href="https://robotyze.org/">
                    </Button>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Robotyze LLC
                    </h4>
                  <p className="m-0 text-sm">
                    I started Robotyze to contribute toward the education of future engineers.
                    </p>
                </div>
                <Button tag="a" color="primary" wideMobile href="https://robotyze.org/">
                  Robotyze.org
                  </Button>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                  <Button image={Chisp} tag="a" color="light" wideMobile href="https://hichisp.com">
                    </Button>
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Chisp Technologies LLC
                    </h4>
                  <p className="m-0 text-sm">
                    I co-founded Chisp with the initial intent to bring communities together through physical activity.
                    </p>
                </div>
                <Button tag="a" color="primary" wideMobile href="https://hichisp.com">
                  HiChisp.com
                  </Button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
