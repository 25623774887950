import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import PoC from './../../assets/images/PoC.jpg';
import PM from './../../assets/images/PM.svg';
import Dev from './../../assets/images/Tank Level.gif';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Professional Experience',
    paragraph: 'I\'ve had to wear many hats while transforming ideas into products (and managing the details and people in between):'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Project Management
                  </div>
                <h3 className="mt-0 mb-12">
                  Product Owner
                  </h3>
                <p className="m-0">
                  I've had to take ownership of projects that have had multiple stakeholders and developers on them. This required me to:
                  <ul>
                    <li>simplify and explain complicated projects</li>
                    <li>estimate timelines and resources needed</li>
                    <li>identify risk mitigation strategies</li>
                    <li>reprioritize project needs quickly</li>
                  </ul>
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={PM}
                  alt="Project Management"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Proof of Concepts
                  </div>
                <h3 className="mt-0 mb-12">
                  Solutions Architect
                  </h3>
                <p className="m-0">
                  As a solutions architect, I've been responsible for the "big picture" ideas (and architecture diagrams) which were initiated as PoCs using things like:
                  <ul>
                    <li>Single Board Computers (Raspberry Pi, Nvidia Jetson, etc.)</li>
                    <li>3D Modeling (Catia, Blender)</li>
                    <li>Physical Machining/Apparatus Construction</li>
                    <li>Microcontrollers (Arduino)</li>
                    <li>Wired and Programmed Various Sensors/Motors/Pumps</li>
                  </ul>
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={PoC}
                  alt="Proof of Concepts"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  SDLC Lifecycle
                  </div>
                <h3 className="mt-0 mb-12">
                  Developer
                  </h3>
                <p className="m-0">
                  As a developer, I've recently been focused on creating serverless solutions in AWS via Python. I've also had to work with tools for general system integration and device configuration:
                  <ul>
                    <li>Servers/ETL/Databases</li>
                    <li>IAM/SSO Authorization</li>
                    <li>APIs (design and implementation)</li>
                    <li>Machine Learning/Computer Vision</li>
                    <li>HTML/JavaScript/CSS</li>
                    <li>Networking</li>
                  </ul>
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={Dev}
                  alt="Development"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
